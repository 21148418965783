export const ru = {
  "title": "Хижняк Анна - Портфолио",
  "name": "Хижняк Анна",
  "portfolio": "Портфолио",
  "cv": "Резюме",
	"small": "Маленькие задачи",
  "edu": "Образование/Опыт",
  "openProject": "Открыть сайт",
  "descProjectHobbyart": "флексбокс, грид, jquery, java script, слайдер, переключатель, анимация, адаптивный дизайн",
  "descProjectHillel": "флексбокс, грид, jquery, java script, слайдер, переключатель, адаптивный дизайн, regexp, валидация форм",
  "descProjectLocalize": "флексбокс, адаптивный дизайн, jquery",
  "descProjectMeliorit": "флексбокс, jquery, слайдер, адаптивный дизайн",
  "descProjectBeauty": "флексбокс, jquery, модальное окно, адаптивный дизайн",
  "descProjectFoxDigital": "флексбокс, jquery, java script, слайдер, переключатель цветовой схемы, адаптивный дизайн",
  "descProjectFutureAcademy": "флексбокс, грид, java script, bootstrap, слайдер, табы",
  "descProjectWPMeliorit": "wordpress, флексбокс, jquery, слайдер, адаптивный дизайн",
  "descProjecReactUsers": "java script, React, Redux, Rest API",
  "smallUserListLabel": "Список пользователей",
  "smallPromoLabel": "Генератор промо-кодов",
  "smallExchangeLabel": "Обмен валют",
	"smallSpinWheelLabel": "Колесо фортуны",
	"smallDeckCardsLabel": "Колода карт",
  "experienceLabel": "Опыт",
  "educationLabel": "Образование",
  "viewDetails": "Подробности"
}
