export const uk = {
  "title": "Хiжняк Анна - Портфолiо",
  "name": "Хiжняк Анна",
  "portfolio": "Портфолiо",
  "cv": "Резюме",
  "edu": "Освіта/Досвід",
	"small": "Маленькi задачi",
  "openProject": "Відкрити сайт",
  "descProjectHobbyart": "флексбокс, грід, jquery, java script, слайдер, перемикач, анімація, адаптивний дизайн",
  "descProjectHillel": "флексбокс, грід, jquery, java script, слайдер, перемикач, адаптивний дизайн, regexp, валідація форм",
  "descProjectLocalize": "флексбокс, адаптивний дизайн, jquery",
  "descProjectMeliorit": "флексбокс, jquery, слайдер, адаптивний дизайн",
  "descProjectBeauty": "флексбокс, jquery, модальне вікно, адаптивний дизайн",
  "descProjectFoxDigital": "флексбокс, jquery, java script, слайдер, перемикач кольору сайту, адаптивний дизайн",
  "descProjectFutureAcademy": "флексбокс, грід, java script, bootstrap, слайдер, таби",
  "descProjectWPMeliorit": "wordpress, флексбокс, jquery, слайдер, адаптивний дизайн",
  "descProjecReactUsers": "java script, React, Redux, Rest API",
  "smallUserListLabel": "Список користувачів",
  "smallPromoLabel": "Генератор промо-кодів",
  "smallExchangeLabel": "Обмін валют",
	"smallSpinWheelLabel": "Колесо фортуни",
	"smallDeckCardsLabel": "Колода карт",
  "experienceLabel": "Досвід",
  "educationLabel": "Освіта",
  "viewDetails": "Подробиці"
}
